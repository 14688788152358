
import { getSliceComponentProps } from '@prismicio/vue/components';
import featuredBlockAdapter, {
  withTabsAdapter as withTabsFeaturedBlockAdapter,
} from '@/slices/FeaturedBlock/adapter';

export default {
  name: 'FeaturedBlock',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      sliceData: null,
    };
  },
  async fetch() {
    const adapter =
      this.slice.variation === 'withTabs'
        ? withTabsFeaturedBlockAdapter
        : featuredBlockAdapter;

    const data = await adapter(this.slice, {
      i18n: this.$i18n,
      prismic: this.$prismic,
      enhancedLinkSerializer: this.$enhancedLinkSerializer,
    });
    this.sliceData = data;
  },
};
