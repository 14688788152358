import { stripRichTextWrapperTag } from '../html';

export default (list) =>
  list.map((listItem) => ({
    title:
      typeof listItem.name === 'string'
        ? listItem.name
        : stripRichTextWrapperTag(listItem.name),
    subtitle:
      listItem.description && stripRichTextWrapperTag(listItem.description),
    checked: listItem.included,
  }));
