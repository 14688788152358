import { stripRichTextWrapperTag, stripRichTextLineBreak } from '~/utils/html';
import { adapter as imageAdapter } from '~/components/cloud-image/utils';
import { productFull } from '~/utils/adapters/product';
import checklist from '~/utils/adapters/checkList';
import getPrice from '~/utils/get-price';
import { routerLinks } from '~/utils/fetch-links';

const getTitle = (title, card = {}) => {
  const cardTitle = card.card_title
    ? stripRichTextLineBreak(stripRichTextWrapperTag(card.card_title))
    : title;

  return cardTitle || title;
};

const offerCard = (item, i18n, enhancedLinkSerializer) => {
  const isStandalone = item.type === 'offer';
  let card = item?.data || {};

  if (!isStandalone) {
    card = (card?.card && card?.card[0]) || {};
  }

  const link = isStandalone
    ? card.offer_link
    : { ...item, link_type: 'Document' };
  const title = getTitle(item.title, card);

  link.rel = 'nofollow';

  return {
    title,
    description: card.card_description
      ? stripRichTextWrapperTag(card.card_description)
      : null,
    primaryLink: item.data?.primary_link
      ? {
          link: enhancedLinkSerializer(item.data.primary_link),
          label: item.data?.primary_link_label || i18n.t('cta.get-a-rate'),
        }
      : null,
    ariaLabel: i18n.t('cta.read-more-aria', { title }),
    image:
      card && card.card_image
        ? imageAdapter(card.card_image, card.card_image_alt)
        : null,
  };
};

const regionCard = (item, i18n, enhancedLinkSerializer) => {
  const isStandalone = item.type === 'offer';
  let card = item?.data || {};

  if (!isStandalone) {
    card = (card?.card && card?.card[0]) || {};
  }

  if (!item) return null;
  const link = { ...item, link_type: 'Document' };
  const title = getTitle(item.title, card);

  return {
    title,
    tag: item.data?.breadcrumb_title || null,
    primaryLink: link
      ? {
          link: enhancedLinkSerializer(link),
          label: i18n.t('cta.read-more'),
        }
      : null,
    ariaLabel: i18n.t('cta.read-more-aria', { title }),
    image:
      card && card.card_image
        ? imageAdapter(card.card_image, card.card_image_alt)
        : null,
  };
};

const warrantyCard = (item, i18n) => {
  const currency = item.data.currency || 'EUR';
  const price = item.data.price;
  const locale = i18n.localeProperties.code;
  const formater = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'EUR',
    currencyDisplay: ['EUR', 'GBP'].includes(currency)
      ? 'narrowSymbol'
      : 'code',
  });

  return {
    name: item.data.title,
    price:
      price &&
      getPrice(
        formater.format(price),
        item.data.price_frequency,
        item.data.legal_reference,
        i18n,
        { bold: true, twoDecimals: true, withoutFormat: true }
      ),
    warranties: checklist(item.data.warranties),
    optionsTitle:
      item.data.options?.length > 0
        ? i18n.t('warrantyCard.options-label')
        : null,
    options: item.data.options?.length > 0 ? checklist(item.data.options) : [],
  };
};

const getType = (type) => {
  if (type === 'page_offer') {
    return 'market';
  } else if (type === 'product_page') {
    return 'product';
  } else if (type === 'page_region') {
    return 'region';
  } else {
    return type;
  }
};

const getCard = (item, i18n, enhancedLinkSerializer) => {
  const type = getType(item.type);

  const cardData =
    (type === 'region' && regionCard(item, i18n, enhancedLinkSerializer)) ||
    (type === 'market' && offerCard(item, i18n, enhancedLinkSerializer)) ||
    (type === 'product' && productFull(item, i18n, enhancedLinkSerializer)) ||
    (type === 'module_warranty_card' && warrantyCard(item, i18n));

  if (!cardData) {
    return null;
  }

  const cardType = () => {
    if (type === 'module_warranty_card') {
      return 'warranty-card';
    } else if (type === 'region' || type === 'market') {
      return 'market-card';
    } else return `${type}-card`;
  };

  return {
    type: cardType(),
    data: cardData,
  };
};

const fetchedItems = async (items, prismic, lang) => {
  if (!items) {
    return [];
  }

  const fetchIds = [];
  const fetchIdsIndex = {};
  const sanitizedItems = [...items];

  function checkLink(link = {}) {
    const linkData = link.data;

    return (
      linkData && (linkData.market || linkData.product || linkData.submarket)
    );
  }

  sanitizedItems.forEach(
    ({ featured_block_card: featuredBlockCard }, index) => {
      const { type, id } = featuredBlockCard;

      let card = featuredBlockCard.data;

      if (type === 'product_page') {
        card =
          (featuredBlockCard?.card && featuredBlockCard.data?.card[0]) || {};
      }

      if (
        checkLink(card?.cta_primary_link) ||
        checkLink(card?.cta_secondary_link) ||
        !featuredBlockCard.data
      ) {
        fetchIds.push(id);
        fetchIdsIndex[id] = index;
      }
    }
  );

  if (fetchIds.length) {
    const cardRequest = await prismic.api.getByIDs(fetchIds, {
      pageSize: 30,
      fetchLinks: routerLinks,
      lang,
    });

    cardRequest.results.forEach((entry) => {
      const { id } = entry;
      const itemIndex = fetchIdsIndex[id];
      sanitizedItems[itemIndex].featured_block_card = entry;
    });
  }

  return sanitizedItems;
};

export const sanitizeModule = (module, i18n, enhancedLinkSerializer) =>
  module
    .map(
      ({
        featured_block_card: featuredBlockCard,
        featured_block_description: featuredBlockDescription,
        featured_block_legal_reference: featuredBlockLegalReference,
      }) => {
        if (!featuredBlockCard.data) return null;

        if (
          featuredBlockCard.type === 'product_page' ||
          featuredBlockCard.type === 'product'
        ) {
          featuredBlockCard.data.card_description = featuredBlockDescription;
        }
        if (
          featuredBlockCard.type === 'product_page' ||
          featuredBlockCard.type === 'product' ||
          featuredBlockCard.type === 'warranty-card'
        ) {
          featuredBlockCard.data.legal_reference =
            featuredBlockLegalReference || null;
        }

        return getCard(featuredBlockCard, i18n, enhancedLinkSerializer);
      }
    )
    .filter(Boolean);

const withTabsAdapter = async (
  { primary, items },
  { i18n, prismic, enhancedLinkSerializer }
) => {
  const lang = i18n.localeProperties.iso;

  const itemsData = await fetchedItems(items, prismic, lang);

  const sorted = itemsData.reduce((acc, item) => {
    if (!acc[item.featured_block_tag]) {
      acc[item.featured_block_tag] = [];
    }
    acc[item.featured_block_tag].push(item);
    return acc;
  }, {});

  return {
    title: stripRichTextWrapperTag(primary.featured_block_title),
    tabs: Object.keys(sorted).map((key) => {
      return {
        name: key,
        cards: sorted[key]
          .map(
            ({
              featured_block_card: featuredBlockCard,
              featured_block_description: featuredBlockDescription,
              featured_block_legal_reference: featuredBlockLegalReference,
            }) => {
              if (
                featuredBlockCard.type === 'product_page' ||
                featuredBlockCard.type === 'product'
              ) {
                featuredBlockCard.data.card_description =
                  featuredBlockDescription;
              }
              if (
                !!featuredBlockLegalReference &&
                (featuredBlockCard.type === 'product_page' ||
                  featuredBlockCard.type === 'product' ||
                  featuredBlockCard.type === 'warranty-card')
              ) {
                featuredBlockCard.data.legal_reference =
                  featuredBlockLegalReference;
              }

              return getCard(featuredBlockCard, i18n, enhancedLinkSerializer);
            }
          )
          .filter(Boolean),
      };
    }),
  };
};

export { withTabsAdapter };

export default async (
  { primary, items },
  { i18n, prismic, enhancedLinkSerializer }
) => {
  const lang = i18n.localeProperties.iso;
  const itemsData = await fetchedItems(items, prismic, lang);
  const actions = [];

  if (
    primary.primary_link &&
    primary.primary_link_label &&
    primary.primary_link_type
  ) {
    actions.push({
      link: {
        ...enhancedLinkSerializer(primary.primary_link),
        variant:
          primary.primary_link_type === 'Primary' ? 'primary' : 'secondary',
      },
      label: primary.primary_link_label,
    });
  }

  if (
    primary.secondary_link &&
    primary.secondary_link_label &&
    primary.secondary_link_type
  ) {
    actions.push({
      link: {
        ...enhancedLinkSerializer(primary.secondary_link),
        variant:
          primary.secondary_link_type === 'Primary' ? 'primary' : 'secondary',
      },
      label: primary.secondary_link_label,
    });
  }

  if (!itemsData) {
    return null;
  }

  return {
    title: stripRichTextWrapperTag(primary.featured_block_title),
    fixed: primary.featured_block_fixed_cards_width,
    type: primary.featured_block_type,
    cards: sanitizeModule(itemsData, i18n, enhancedLinkSerializer),
    actions,
  };
};
